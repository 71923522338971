import PropTypes from "prop-types";
import React from "react";
//import React, { useEffect } from "react";
import "../css/style.css";
import Header from "./header";
import Footer from "./footer";
// import { browserName } from 'react-device-detect';
// import AOS from 'aos';
//import 'aos/dist/aos.css';

function Layout({ children, addClassName = "" }) {

  // useEffect(() => {
  //   AOS.init({
  //     // Global settings:
  //     disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  //     startEvent: (browserName === "Chrome") ? 'DOMContentLoaded' : 'load',
  //     initClassName: 'aos-init', // class applied after initialization
  //     animatedClassName: 'aos-animate', // class applied on animation
  //     useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  //     disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  //     debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  //     throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  //     // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  //     offset: 0, // 元のトリガーポイントからのオフセット（ピクセル単位）
  //     delay: 50, // アニメーションが始まるまでの遅延時間（0-3000の間で指定。50ms毎）
  //     duration: 600, // アニメーション開始から終了までの時間（0-3000の間で指定。50ms毎）
  //     easing: 'ease-out-sine', // default easing for AOS animations
  //     once: true, // スクロール中にアニメーションを１回だけ実行するか
  //     mirror: false, // スクロール中にアニメーションするか
  //     anchorPlacement: 'top-bottom', // トリガーポイントの設定。要素のどの位置がウィンドウのどの位置にきたら実行させるか

  //   });
  //   // AOS.refresh();
  // }, []);

  const style = { "fontFamily": 'Noto Sans JP, sansSerif' };

  return (
    <div className="my-0 mx-auto flex flex-col min-h-screen text-gray-900 tracking-wider overflow-x-hidden" style={style}>
      <Header />

      {/* <main className={addClassName + " leading-loose text-lg xl:text-sm xl:font-light md:tracking-wider flex-1 w-full mx-auto mt-24 xl:mt-20"}> */}
      <main className={addClassName + " leading-loose text-sm font-light tracking-wider flex-1 w-full mx-auto mt-24 xl:mt-20"}>
        <article>
          {children}
        </article>
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  addClassName: PropTypes.string,
};

export default Layout;
