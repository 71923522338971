import { Link } from "gatsby";
import React, { useState } from "react";
import { slide as Menu } from 'react-burger-menu'
import Logo from "../images/logo.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  const menu = [
    {
      route: `/about/`,
      title: `私たちについて`,
      add: ``,
    },
    {
      route: `/business/`,
      title: `事業内容`,
      add: ``,
    },
    {
      route: `/club/`,
      title: `イベント・クラブ活動`,
      add: ``,
    },
    {
      route: `/employment/`,
      title: `採用情報`,
      add: ``,
    }
  ];

  const styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '40px',
      height: '30px',
      top: '36px',
      right: '20px'
    },
    bmBurgerBars: {
      background: '#333333',
      borderRadius: '10px'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '36px',
      width: '36px',
      top: '10px',
      right: '25px'
    },
    bmCross: {
      background: '#eeeeee',
      height: '36px',
      width: '6px'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      top: 0
    },
    bmMenu: {
      background: '#272a6f',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
      right: 0
    },
    bmMorphShape: {
      fill: '#272a6f'
    },
    bmItemList: {
      color: '#272a6f',
      padding: '0.8em'
    },
    bmItem: {
      display: 'block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.5)',
      top: 0
    }
  }

  return (
    <header className="bg-white fixed w-full z-50">
      <div className="flex flex-wrap flex-row items-center justify-between mx-auto p-5 py-9 xl:py-6 md:pr-10">
        <Link to="/">
          <h1>
            <img alt="logo" src={Logo} />
          </h1>
        </Link>

        <div className="hidden md:block">
          <button
            className="items-center block px-3 py-2 text-black border border-white rounded md:hidden"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>

          <nav
            className={`${isExpanded ? `block` : `hidden`
              } md:block md:items-center w-full md:w-auto text-xs xl:text-sm  `}
          >
            {menu.map((link) => (
              <Link
                className={"block mt-4 no-underline md:inline-block md:mt-0 ml-6 lg:ml-14" + link.add}
                key={link.title}
                to={link.route}
              >
                {link.title}
              </Link>
            ))}
          </nav>
        </div>
      </div>

      <div className="md:hidden">
        <Menu
          right
          styles={styles}
        >
          {menu.map((link) => (
            <Link
              className={"mt-4 text-white " + link.add}
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </Menu>
      </div>

    </header>
  );
}

export default Header;
